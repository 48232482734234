import hotkeys from "hotkeys-js";
import { useCallback, useEffect } from "react";
export function useHotkeys(keys, callback, options, deps) {
    if (options instanceof Array) {
        deps = options;
        options = undefined;
    }
    const { enableOnTags, filter } = options || {};
    const memoisedCallback = useCallback(callback, deps || []);
    useEffect(() => {
        if (options && options.enableOnTags) {
            hotkeys.filter = ({ target, srcElement }) => {
                // @ts-ignore
                const targetTagName = (target && target.tagName) || (srcElement && srcElement.tagName);
                return Boolean(targetTagName && enableOnTags && enableOnTags.includes(targetTagName));
            };
        }
        if (filter)
            hotkeys.filter = filter;
        hotkeys(keys, options || {}, memoisedCallback);
        return () => hotkeys.unbind(keys, memoisedCallback);
    }, [memoisedCallback, options]);
}
